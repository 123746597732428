import React, { useEffect, useRef } from 'react';
import Ticket from "./ticket";
import './style.scss';
import ModalPayment from "./ModalPayment";
import ModalMore from "./ModalMore";
import PageTitle from "../../Components/pageTitle";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
// eslint-disable-next-line
import { useGetTicketsQuery, useGetTicketsTestQuery } from "../../redux/api";
import { Skeleton } from "@mui/material";
import { indigo } from '@mui/material/colors';
//import axios from "axios";

const Tickets = () => {

    const { isLoading, error } = useGetTicketsQuery();
    const { tickets, isOpenModalBuy, isOpenModalMore } = useAppSelector((state: RootState) => state.ticketsModalStore);

    const ticketsWrapperRef = useRef(null);

    useEffect(() => {
        const updateItemsGap = (wrapper) => {
            return parseFloat(getComputedStyle(wrapper).getPropertyValue("--items-gap")) || 5;
        };

        const handleScroll = () => {
            const wrapper = ticketsWrapperRef.current;
            if (!wrapper) return;

            const items = wrapper.querySelectorAll('.tickets_category_title, .ticket');
            const wrapperRect = wrapper.getBoundingClientRect();
            const wrapperTopPos = wrapperRect.top + window.scrollY;
            const wrapperHeight = wrapperRect.height;
            const elGap = updateItemsGap(wrapper);
            const windowHeight = window.innerHeight;

            items.forEach((item, index) => {
                const itemHeight = wrapperHeight / items.length;
                const itemTop = itemHeight * index;
                const scrollY = window.scrollY + windowHeight / 2 - wrapperTopPos;
                const zoomFactor = (scrollY - itemTop) / (wrapperHeight + itemTop);
                const scaleValue = Math.max(1 - Math.abs(zoomFactor), 0.5);
                const translateYValue = elGap * index;
                item.style.transform = `scale(${scaleValue}) translateY(${translateYValue}px)`;
            });
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    const products = () => {
        if(!isLoading && !error) {
            // eslint-disable-next-line
            return tickets?.map((category: any, categoryIndex) => {
                return (
                // <div className="tickets_category" key={categoryIndex}>
                <>
                    <h2 className="tickets_category_title" key={categoryIndex}>{category.title}</h2>
                    {category.items.map((item, itemIndex) => {
                        return (
                            <Ticket 
                                key = {itemIndex}
                                categoryId = {category.id}
                                info = {item}
                            />
                        );
                    })}
                    </>
                // </div>
                );
            });
        } else {
            return (
                <div className="tickets_loader">
                    <Skeleton 
                        variant="rounded" 
                        width="232px" 
                        height="30px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "10px", marginBottom: "22px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "40px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="232px" 
                        height="30px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "10px", marginBottom: "22px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="232px" 
                        height="30px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "10px", marginBottom: "22px"}}
                    />
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height="178px"
                        sx={{ bgcolor: indigo[200] , borderRadius: "16px", marginBottom: "20px"}}
                    />
                </div>
            );
        }
    }
        
    return (
        <>
            <PageTitle 
                title="Viva Braslav"
                subtitle="Билеты всех категорий"
            />
            <div className={isOpenModalBuy ? 'tickets open-payment' : 'tickets' && isOpenModalMore ? 'tickets open-more' : 'tickets'}>
                <div className='tickets_container'>
                {/* <div className="tickets_wrapper" ref={ticketsWrapperRef}> */}
                <div className="tickets_wrapper" >
                        {products()}                        
                    </div>
                </div>
                <ModalMore />
                <ModalPayment />
            </div>
        </>
    );
}

export default Tickets;