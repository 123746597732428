import './style.scss';
import PageTitle from '../../Components/pageTitle'
import Details from './Details'
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';


import iconCamping from '../../img/places/camp-icon.svg';
import iconGlamping from '../../img/places/glam-icon.svg';

import { useGetTicketsQuery, useGetTicketsTestQuery } from "../../redux/api";
import { Skeleton } from '@mui/material';
import ModalMore from '../tickets/ModalMore';
import ModalPayment from '../tickets/ModalPayment';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import mapImg from '../../img/places/mapcamp.jpg';


const Places = () => {

    const { isLoading, error } = useGetTicketsQuery();
    const { tickets, isOpenModalBuy, isOpenModalMore } = useAppSelector((state: RootState) => state.ticketsModalStore);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [pathname]);

    return (
        <>
            <PageTitle 
                title="Проживание"
                subtitle="Варианты размещения гостей фестиваля"
            />
            <div className={isOpenModalBuy ? 'places open-payment' : 'places' && isOpenModalMore ? 'places open-more' : 'places'}>
                <div className="places_container">
                    <div className="places_wrapper">
                        {!isLoading && !error ? (<>
                                        <Details 
                                            available={true}
                                            title="Кемпинг"
                                            description='Кемпинг – это специально оборудованная, огороженная и освещённая территория с возможностью выхода к воде. Имея при себе билет «Кемпинг 4 дня», гость может установить не более одной палатки размером, не превышающим 3х2 метра.<br/><br/>Расположен в 200 метрах от места проведения международного спортивно-музыкального фестиваля «Viva Braslav», оборудован душевыми кабинками, биотуалетами, рукомойниками, контейнерами для сбора мусора, зоной «Барбекю» с установленными мангалами. Также на территории кемпинга организованы зоны фуд-корта, кальянные, лаунч-зоны, станции для подзарядки гаджетов. Для удобства и безопасности гостей кемпинга, организовано круглосуточное дежурство персонала кемпинга, скорой медицинской помощи, МЧС, представителей правоохранительных органов.'
                                            icon={iconCamping}
                                            tickets={[tickets[1]['items'][0]]}
                                            rules=""
                                            img={null}
                                        />
                                        <Details 
                                            available={true}
                                            title="Глэмпинг"
                                            description='Глэмпинг – это специально оборудованная, огороженная и освещённая территория, на которой гость может с комфортом расположиться в уже установленных деревянных или тентовых домиках. Что бы пройти на территорию глэмпинга, необходим билет «Глэмпинг comfort 4 дня», «Глэмпинг 4 дня» или «Глэмпинг luxury 4 дня» и билет «Стандарт 2 дня» или «VIP 2 дня» на международный спортивно-музыкальный фестиваль «Viva Braslav».<br/><br/>Расположен в 200 метрах от места проведения международного спортивно-музыкального фестиваля «Viva Braslav». Оборудован отдельной парковкой для авто (расстояние до территории глэмпинга около 200 метров), зоной «Барбекю» с установленным мангалом, биотуалетами, рукомойниками, контейнерами для сбора мусора. Также на территории глэмпинга   организованы зона фуд-корта, кальянная зона, душевые кабинки, данные услуги не входят в стоимость билета и оплачиваются отдельно. Для удобства и безопасности гостей глэмпинга, организовано дежурство персонала глэмпинга, специалистов медицинской помощи, МЧС и представителей правоохранительных органов.'
                                            icon={iconGlamping}
                                            tickets={[tickets[1]['items'][2],tickets[1]['items'][1],tickets[1]['items'][3]]}
                                            rules=""
                                            img={null}
                                        />
                                        <Details 
                                            available={true}
                                            title="Garage camp"
                                            description='Garage camp – это специально оборудованная, огороженная и освещённая локация. Имея при себе билет «Garage camp 4 дня», гость может установить одну палатку, размером не превышающую 3х2 метра. 
                                            Garage camp расположен в 1000 м. от фестиваля, оборудован санитарной зоной, контейнерами для сбора мусора, зоной «Барбекю» с установленными мангалами. Для удобства и безопасности гостей кемпинга, организовано круглосуточное дежурство персонала кемпинга, скорой медицинской помощи, МЧС, представителей правоохранительных органов.'
                                            icon={iconGlamping}
                                            tickets={[tickets[1]['items'][4]]}
                                            rules=""
                                            img={null}
                                        />
                                        {/* <Details 
                                            available={true}
                                            title="Разрешенные места для установки палаток"
                                            description=''
                                            icon={iconGlamping}
                                            tickets={[]}
                                            rules=""
                                            img={mapImg}
                                        /> */}
                                        {/* <Details 
                                            available={true}
                                            title="Разрешенные места для установки палаток"
                                            description='Скоро здесь появится информация'
                                            icon={iconGlamping}
                                            tickets={[]}
                                            rules=""
                                            img={null}
                                        /> */}
                                        </>) 
                                    : 
                                    (
                                    <>
                                    <Skeleton 
                                        variant="rounded" 
                                        width="100%" 
                                        height="479px"
                                        sx={{ bgcolor: '#EDEDED' , borderRadius: "16px", marginBottom: "20px"}}
                                    />
                                    <Skeleton 
                                        variant="rounded" 
                                        width="100%" 
                                        height="139px"
                                        sx={{ bgcolor: '#EDEDED' , borderRadius: "16px", marginBottom: "20px"}}
                                    />
                                    </>)}
                        
                    </div>
                </div>
                <ModalMore />
                <ModalPayment />
            </div>
            
        </>
    )
}

export default Places;