import PageTitle from "../../Components/pageTitle";
import './style.scss';
import photo from '../../img/contacts/photo1.jpeg';

const Contacts = () => {
    return(
        <>
        <PageTitle
            title="Контакты"
            subtitle=""
        />
        <div className="contacts">
            <div className="contacts_container">
                <div className="contacts_wrapper">
                    <span className="contacts_text">Масштабный музыкальный уикенд страны, ежегодно проводящийся летом на территории Национального парка «Браславские озера» на северо-западе страны. Проводится с 2013 года. Команда Viva Braslav открыта активным и креативным людям.</span>
                    <div className="contacts_mails">
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Партнерство:</span>
                            <a href="mailto:info@vivabraslav.by" className="contacts_mails_item_link">info@vivabraslav.by</a>
                        </div>
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Торговля, развлечения:</span>
                            <a href="mailto:torg@vivabraslav.by" className="contacts_mails_item_link">torg@vivabraslav.by</a>
                        </div>
                        {/* <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Артисты:</span>
                            <a href="mailto:kma@vivabraslav.by" className="contacts_mails_item_link">kma@vivabraslav.by</a>
                        </div> */}
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">PR:</span>
                            <a href="mailto:pr@vivabraslav.by" className="contacts_mails_item_link">pr@vivabraslav.by</a>
                        </div>
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Спорт:</span>
                            <a href="mailto:sport@vivabraslav.by" className="contacts_mails_item_link">sport@vivabraslav.by</a>
                        </div>
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Билеты:</span>
                            <a href="mailto:support@ticketok.by" className="contacts_mails_item_link">support@ticketok.by</a>
                        </div>
                        <div className="contacts_mails_item">
                            <span className="contacts_mails_item_name">Инфолиния:</span>
                            <div className="contacts_mails_item_block">
                                <a href="tel:+375292771059" className="contacts_mails_item_link">+375 (29) 277-10-59</a>
                                <p className="contacts_mails_item_descr">(пн-пт 9:00 - 18:00)</p>
                            </div>
                        </div>
                    </div>
                    <div className="contacts_photo">
                        <img src={photo} alt="contact viva braslav" className="contacts_photo_img" />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Contacts;