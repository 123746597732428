import './style.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay } from "swiper";

import generalPatner from '../../img/footer/general_partner_visa.png';
import partner1 from '../../img/footer/onliner.jpg';
import partner2 from '../../img/footer/partner_3.png';
import partner3 from '../../img/footer/partner_2.png';

import partnerCoca from '../../img/footer/partner_6.png';
import partnerKonunarka from '../../img/footer/komunarka-new.png';
import partnerSignet from '../../img/footer/signet.jpg';
import partnerDoritos from '../../img/footer/doritos.png';

import partnerActiveLeasing from '../../img/footer/activeleasing.png';
import partnerAutocenter from '../../img/footer/autocenter.png';
import partnerDarida from '../../img/footer/darida.png';

import partner4 from '../../img/footer/partner_4.png';
import partnerBB from '../../img/footer/bb_logo.png';
import partnerVIPE_logo from '../../img/footer/VIPE_logo.png';
import tuborgGreen from '../../img/footer/tuborg_green.svg';
import yandex from '../../img/footer/partner_7.png';
import pepsi from '../../img/footer/partner_8.png';

import instagramIcon from '../../img/footer/social/Instagram.svg';
import vkIcon from '../../img/footer/social/Vkontakte.svg';
import facebookIcon from '../../img/footer/social/Facebook.svg';
import telegramIcon from '../../img/footer/social/Telegram.svg';
import youtubeIcon from '../../img/footer/social/Youtube.svg';
import tiktokIcon from '../../img/footer/social/TikTok.svg';

import photo1 from '../../img/footer/social/photos/photo_1.jpg';
import photo2 from '../../img/footer/social/photos/photo_5.jpg';
import photo3 from '../../img/footer/social/photos/photo_6.jpg';
import photo4 from '../../img/footer/social/photos/photo_7.jpg';
import photo5 from '../../img/footer/social/photos/photo_8.jpg';


import paymentsLogo from '../../img/footer/logo_payments2205.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Footer = () => {


    const [cookie, setCookie] = useState(localStorage.getItem("cookie"));

    const agreeCookie = () => {
        setCookie("true");
        localStorage.setItem("cookie", "true");
    }

    return (
        <>
            <div className="cookie" style={cookie ? {transform: 'translateY(120%)'} : {}}>
                <span className="cookie_text">Для обеспечения удобства пользователей сайта <a href="https://vivabraslav.by/docs/cookie.pdf" rel="noreferrer" target="_blank" className="">используются cookie</a> 🍪</span>
                <div className="cookie_btn" onClick={()=>{agreeCookie()}}>Принять</div>
            </div>
            <div className="social">
                <div className="social_container">
                    <div className="social_wrapper">
                        <div className="social_links">
                            <h1 className="social_links_title">Подписывайся на наши социальные&nbsp;сети</h1>
                            <h2 className="social_links_text">Самые актуальные новости и события Viva&nbsp;Braslav</h2>
                            <div className="social_links_items">
                                <a href="https://www.instagram.com/vivabraslav.real" rel="noreferrer" target="_blank" className="social_links_item">
                                    <img src={instagramIcon} alt="Instagram" className="social_links_item_img" />
                                </a>
                                <a href="https://vk.com/vivabraslav.real" rel="noreferrer" target="_blank" className="social_links_item">
                                    <img src={vkIcon} alt="VK" className="social_links_item_img" />
                                </a>
                                <a href="https://www.facebook.com/vivabraslav.real" rel="noreferrer" className="social_links_item" target="_blank">
                                    <img src={facebookIcon} alt="Facebook" className="social_links_item_img" />
                                </a>
                                <a href="https://t.me/vivabraslav_official" rel="noreferrer" className="social_links_item" target="_blank">
                                    <img src={telegramIcon} alt="Telegram" className="social_links_item_img" />
                                </a>
                                <a href="https://www.youtube.com/@VivaBraslavOpenAir" rel="noreferrer" className="social_links_item" target="_blank">
                                    <img src={youtubeIcon} alt="Youtube" className="social_links_item_img" />
                                </a>
                                <a href="https://www.tiktok.com/@vivabraslav" rel="noreferrer" className="social_links_item" target="_blank">
                                    <img src={tiktokIcon} alt="TikTok" className="social_links_item_img" />
                                </a>
                            </div>
                        </div>
                        <div className="social_photos">
                            <Swiper
                                allowTouchMove={false}
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false
                                }}
                                loop={true}
                                className="social_photos_swiper"
                                breakpoints={{
                                370: {
                                    slidesPerView: 2,
                                },
                                574: {
                                    slidesPerView: 3,
                                },
                                775: {
                                    slidesPerView: 4,
                                },
                                880: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 3,
                                },
                                1300: {
                                    slidesPerView: 4,
                                },
                                }}
                                modules={[Autoplay]}
                            >
                                <SwiperSlide className="social_photo_item" key={"footer_1"}>
                                    <img src={photo1} alt="Viva Braslav" className="social_photos_item_img" />
                                </SwiperSlide>
                                <SwiperSlide className="social_photo_item" key={"footer_2"}>
                                    <img src={photo2} alt="Viva Braslav" className="social_photos_item_img" />
                                </SwiperSlide>
                                <SwiperSlide className="social_photo_item" key={"footer_3"}>
                                    <img src={photo3} alt="Viva Braslav" className="social_photos_item_img" />
                                </SwiperSlide>
                                <SwiperSlide className="social_photo_item" key={"footer_4"}>
                                    <img src={photo4} alt="Viva Braslav" className="social_photos_item_img" />
                                </SwiperSlide>
                                <SwiperSlide className="social_photo_item" key={"footer_5"}>
                                    <img src={photo5} alt="Viva Braslav" className="social_photos_item_img" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer_container">
                    <div className="footer_wrapper">
                        {/* <div className="footer_partners">
                            <div className="footer_partners_general">
                                <span className="footer_partners_general_text">Генеральный партнёр</span>
                                <div className="footer_partners_general_logo">
                                    <img src={generalPatner} alt="Альфа Банк" className="footer_partners_general_logo_img" />
                                </div>
                            </div>
                            <div className="footer_partners_others">
                                <span className="footer_partners_others_text">Партнёры</span>
                                <div className="footer_partners_others_logo">
                                    <img src={partner2} alt="Tuborg" className="footer_partners_others_logo_img_3" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partner4} alt="Frudoza" className="footer_partners_others_logo_img_4" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partner1} alt="onliner" className="footer_partners_others_logo_img_1" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partnerBB} alt="belbet" className="footer_partners_others_logo_img_5" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partner3} alt="Новое радио" className="footer_partners_others_logo_img_2" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partnerKonunarka} alt="Komunarka" className="footer_partners_others_logo_img_7" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partnerActiveLeasing} alt="Autoleasing" className="footer_partners_others_logo_img_6" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partnerAutocenter} alt="Autocenter" className="footer_partners_others_logo_img_2" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={partnerDarida} alt="Darida" className="footer_partners_others_logo_img_8" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={yandex} alt="Yandex Music" className="footer_partners_others_logo_img_1" />
                                </div>
                                <div className="footer_partners_others_logo">
                                    <img src={pepsi} alt="Pepsi" className="footer_partners_others_logo_img_3" />
                                </div>
                            </div>
                        </div> */}
                        <div className="footer_organizer">
                            <div className="footer_organizer_info">
                                <span className="footer_organizer_info_title">Организатор</span>
                                <span className="footer_organizer_info_text">Частное предприятие «Левол Групп»<br/>Юридический&nbsp;адрес: 211969,&nbsp;Республика&nbsp;Беларусь,<br/>Витебская&nbsp;область, г.&nbsp;Браслав, ул.&nbsp;Красноармейская&nbsp;д.&nbsp;1/2<br/>УНП&nbsp;391029259, выдано 22.04.2017 Браславским районным исполнительным комитетом. Удостоверение № 2-9/1 от 24.06.2024 г. Выдано Управлением культуры Витебского облисполкома.<br/>Для лиц старше 18 лет.</span>
                                <span className="footer_organizer_info_text">Фестиваль проводится на территории Национального парка «Браславские озера» на северо-западе страны. Проводится&nbsp;с&nbsp;2013&nbsp;года.</span>
                            </div>
                            <div className="footer_info">
                                <span className="footer_info_title">Инфо</span>
                                <div className="footer_info_links">
                                    <Link to="#" className='footer_info_links_item'>Способы оплаты</Link>
                                    <Link to="#" className='footer_info_links_item'>Как совершить заказ</Link>
                                    <Link to="#" className='footer_info_links_item'>Возврат билетов</Link>
                                    <Link to="#" className='footer_info_links_item'>Правила и публичные договоры</Link>
                                </div>
                                <div className="footer_info_logo">
                                    <img src={paymentsLogo} alt="Payment Types" className="footer_info_logo_img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_copyright">
                        <span className="footer_copyright_text">© VIVA BRASLAV. Все права защищены.</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;